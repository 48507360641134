import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { UserIcon, Bars3Icon, BanknotesIcon, TicketIcon, ShoppingCartIcon, PlusCircleIcon, CheckCircleIcon, XCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { useAuthentication } from './components/authentication.jsx';
import AddNewTransactionModal from './components/addNewTransactionModal.jsx';
import UpdateTransactionModal from './components/updateTransactionModal.jsx';
import './components/css/slideOutMenu.css';
import Spinner from './components/spinner.jsx';

const MessageModal = ({ messageContent, onClose, onYes }) => {
  const [firstPart, secondPart] = messageContent.split(":");
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-md w-80">
        {
          firstPart === "Success" ? (
            <div className="mx-auto flex items-center justify-center h-[40px] w-[140px] space-x-2">
              <CheckCircleIcon aria-hidden="true" className="h-6 w-6 rounded-full text-green-600" />
              <label className="text-green-600">{firstPart}!</label>
            </div>
          ) : firstPart === "Error" ? (
            <div className="mx-auto flex items-center justify-center h-[40px] w-[140px] space-x-2">
              <XCircleIcon aria-hidden="true" className="h-6 w-6 rounded-full text-red-600" />
              <label className="text-red-600">{firstPart}!</label>
            </div>
          ) : firstPart === "Are You Sure" ? (
            <div className="mx-auto flex items-center justify-center h-[40px] w-[140px] space-x-2">
              <QuestionMarkCircleIcon aria-hidden="true" className="h-6 w-6 rounded-full text-yellow-600" />
              <label className="text-yellow-600">{firstPart}!</label>
            </div>
          ) : null
        }
        <h2 className="text-xl font-semibold text-center m-2">{secondPart}</h2>
        <div className="mt-4 flex justify-center">
          {firstPart === "Are You Sure" ? (
              <>
                <button
                  className="px-6 py-2 bg-red-500 text-white rounded-md shadow-lg mt-3 mr-2"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  className="px-6 py-2 bg-green-500 text-white rounded-md shadow-lg mt-3"
                  onClick={onYes}
                >
                  Yes
                </button>
              </>
            ) : (
              <button
                className="px-6 py-2 bg-green-500 text-white rounded-md shadow-lg mt-3"
                onClick={onClose}
              >
                OK
              </button>
            )
          }
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {

  const backendHostName = process.env.REACT_APP_BACKEND_HOST_NAME;
  const navigate = useNavigate();
  const { user, setUser, settings, setSettings } = useAuthentication();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageState, setMessageState] = useState('');
  //const [bussinessOpen, setBussinessOpen] = useState(false);

  const [cash, setCash] = useState(0);
  const [receivables, setReceivables] = useState(0);
  const [summaryServices, setSummaryServices] = useState([]);

  const [transactions, setTransactions] = useState([]);
  const [selected_Transaction, setSelected_Transaction] = useState(null);
  const [updateTransactionModalState, setUpdateTransactionModalState] = useState(false);
  const [addNewTransactionModalState, setAddNewTransactionModalState] = useState(false);
  const [transactionIdToDelete, setTransactionIdToDelete] = useState(null);

  const [paymentChange, setPaymentChange] = useState(0);
  
  useEffect(() => {
    if (!user.isAuthenticated) {
      navigate("/");
    }
  }, [user.isAuthenticated, navigate]);

  const toggleMenu = () => {
    if (user.isAdmin) setIsOpen(prev => !prev);
  };

  const toggleBussinessOpen = async () => {
    const today = new Date();
    const utcNow = new Date(today.toISOString()); // Convert to UTC
    const utcPlus8 = new Date(utcNow.getTime() + (8 * 60 * 60 * 1000)); // Adjust to UTC+8

    if (user.isAdmin) {
      setLoading(true);
      
      // Make the API request to update the settings
      const updatedRes = await axios.put(`${backendHostName}/settings/updatesettings.php`, {
        bussiness_open: settings.bussinessOpen === 0 ? 1 : 0,
        date_of_bussiness: formatDate(utcPlus8)
      });
  
      //console.log(updatedRes.data);
      // Convert date_of_bussiness to a Date object
      const dateOfBussiness = new Date(updatedRes.data.date_of_bussiness);
  
      // Check if updatedRes.data.date_of_bussiness is a valid date
      if (isNaN(dateOfBussiness.getTime())) {
        console.error("Invalid date_of_bussiness received:", updatedRes.data.date_of_bussiness);
        return;
      }
  
      // Now you can safely call getDate()
      console.log("Day of the month:", dateOfBussiness.getDate());
  
      // Update settings with the received values
      setSettings({
        bussinessOpen: updatedRes.data.bussiness_open,
        dateOfBussiness: updatedRes.data.date_of_bussiness
      });
  
      setLoading(false);
    }
  };
        
  const formatDate = (d) => {
    if (!d) return ''; // Handle invalid dates
    const dt = new Date(d);
    return `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
  };

  const option = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

  const showDate = (d) => {
    const dt = new Date(d);
    return dt.toLocaleDateString('en-US', option);
  };

  const formatTime = (t) => {
    const dt = new Date(t);
    if (isNaN(dt)) return ''; // Return empty string for invalid date
    let hours = dt.getHours();
    const minutes = dt.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${ampm}`;
  };
  
  const formatNumber = (number) => new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);

  const handleLogout = () => {
    setUser({ isAuthenticated: false, whoIsLoggedIn: null, isAdmin: null });
    navigate("/");
  };

  const handleEmployees = () => {
    navigate("/employees");
  };

  const confirmDeleteTransaction = (id, details) => {
    setTransactionIdToDelete(id); // Set the ID for the transaction to delete
    setMessageState(`Are You Sure:Delete This Transaction? ${details}`);
    //setModalVisible(true); // Show the confirmation modal
  };

  const handleDeleteTransaction = async () => {
    setLoading(true); // Show loading state
    try {
      // API call to delete the transaction
      await axios.delete(`${backendHostName}/transactions/deletetransaction.php?id=${transactionIdToDelete}`);
      
      // Update transactions state
      setTransactions((prevTransactions) => {
        const updatedTransactions = prevTransactions.filter(transaction => transaction.id !== transactionIdToDelete);
  
        // Sorting transactions by `date_time_accepted` in descending order (latest first)
        updatedTransactions.sort((a, b) => new Date(b.date_time_accepted) - new Date(a.date_time_accepted));
  
        // Optionally calculate and update summary state
        const summary = calculateTransactionSummary(updatedTransactions, formatDate(new Date()));
        updateSummaryState(summary);
  
        return updatedTransactions; // Return the updated state
      });
  
      setMessageState("Success: Transaction Deleted");
    } catch (error) {
      setMessageState("Error: Failed to delete the transaction");
    } finally {
      setLoading(false); // Hide loading state
      // Optionally close the modal if it's open
      // setModalVisible(false);
    }
  };
  
  const calculateTransactionSummary = (transactions, currentDate) => {
    // Initialize the transactionSummary object
    const transactionSummary = {
      totalCash: 0,
      totalReceivable: 0,
      totalUnpaid: 0,
      totalPaid: 0,
      fullServiceUnpaid: 0,
      washOnlyUnpaid: 0,
      dryOnlyUnpaid: 0,
      specialServiceUnpaid: 0,
      fullServicePaid: 0,
      washOnlyPaid: 0,
      dryOnlyPaid: 0,
      specialServicePaid: 0
    };

    // Loop through each transaction and calculate the summary values
    transactions.forEach(item => {
      const itemDatePaid = formatDate(item.date_paid);
      const itemDateAccepted = formatDate(item.date_time_accepted);

      // Add to cash if paid today
      if (itemDatePaid === currentDate) {
        transactionSummary.totalCash += item.total;
      } 
      // Add to receivables if not paid, but accepted today
      else if (itemDateAccepted === currentDate && !item.date_paid) {
        transactionSummary.totalReceivable += item.total;
      }
      // Otherwise, still add to receivables if not paid
      else if (!item.date_paid) {
        transactionSummary.totalReceivable += item.total;
      }

      // Handle specific service cases
      switch (item.service) {
        case "Full Service":
          if (itemDatePaid === currentDate && itemDateAccepted === currentDate) {
            transactionSummary.fullServicePaid += item.loads;
            transactionSummary.totalPaid += item.loads;
          } else { 
            if (itemDateAccepted === currentDate) {
              transactionSummary.fullServiceUnpaid += item.loads;
              transactionSummary.totalUnpaid += item.loads;
            }    
          }
          break;

        case "Wash Only":
          if (itemDatePaid === currentDate && itemDateAccepted === currentDate) {
            transactionSummary.washOnlyPaid += item.loads;
            transactionSummary.totalPaid += item.loads;
          } else {
            if (itemDateAccepted === currentDate) {
              transactionSummary.washOnlyUnpaid += item.loads;
              transactionSummary.totalUnpaid += item.loads;
            }
          }
          break;

        case "Dry Only":
          if (itemDatePaid === currentDate && itemDateAccepted === currentDate) {
            transactionSummary.dryOnlyPaid += item.loads;
            transactionSummary.totalPaid += item.loads;
          } else {
            if (itemDateAccepted === currentDate) {
              transactionSummary.dryOnlyUnpaid += item.loads;
              transactionSummary.totalUnpaid += item.loads;
            }
          }
          break;

        case "Special":
          if (itemDatePaid === currentDate && itemDateAccepted === currentDate) {
            transactionSummary.specialServicePaid += item.loads;
            transactionSummary.totalPaid += item.loads;
          } else {
            if (itemDateAccepted === currentDate) {
              transactionSummary.specialServiceUnpaid += item.loads;
              transactionSummary.totalUnpaid += item.loads;
            }
          }
          break;

        default:
      }
    });

    // Return the summary object
    return transactionSummary;
  };

  const updateSummaryState = (summary) => {
    setReceivables(summary.totalReceivable);
    setCash(summary.totalCash);
    setSummaryServices([
      { service: "Full Service", loadsUnpaid: summary.fullServiceUnpaid, loadsPaid: summary.fullServicePaid },
      { service: "Wash Only", loadsUnpaid: summary.washOnlyUnpaid, loadsPaid: summary.washOnlyPaid },
      { service: "Dry Only", loadsUnpaid: summary.dryOnlyUnpaid, loadsPaid: summary.dryOnlyPaid },
      { service: "Special", loadsUnpaid: summary.specialServiceUnpaid, loadsPaid: summary.specialServicePaid },
      { service: "Total Loads (Today)", loadsUnpaid: summary.totalUnpaid, loadsPaid: summary.totalPaid }
    ]);
  };

  const open_UpdateTransactionModal = (transactionToUpdate, initialPaymentChange) => {
    if (settings.bussinessOpen) {
    setSelected_Transaction(transactionToUpdate);
    setPaymentChange(initialPaymentChange);
    setUpdateTransactionModalState(true);
    }
  };

  const close_UpdateTransactionModal = () => { 
    setUpdateTransactionModalState(false); 
  };

  const open_AddNewTransactionModal = () => {
    if (settings.bussinessOpen) {
      setAddNewTransactionModalState(true)
    }
  }

  const close_AddNewTransactionModal = () => { 
    setAddNewTransactionModalState(false); 
  };

  const close_MessageModal = () => {
    setMessageState('');
  };

  // Function to add new transaction
  const addNew_Transaction = async (newTransaction) => {
    setLoading(true);
    const thisTransaction = {
      ...newTransaction,
      bywho: user.whoIsLoggedIn,
      payment_acceptedby: newTransaction.date_paid !== null ? user.whoIsLoggedIn : "" 
    };
    try {
      // Make API request to insert the new transaction
      const res = await axios.post(`${backendHostName}/transactions/insertnewtransaction.php`, thisTransaction);
  
      if (res.data) {
        //console.log('New transaction added:', res.data);
  
        // Add the new transaction to the state
        const addedTransaction = res.data;
        setTransactions((prevTransactions) => {
          const updatedTransactions = [...prevTransactions, addedTransaction];
  
          // Sorting by `date_time_accepted` in descending order (latest first)
          updatedTransactions.sort((a, b) => new Date(b.date_time_accepted) - new Date(a.date_time_accepted));

          const summary = calculateTransactionSummary(updatedTransactions, formatDate(new Date()));

          updateSummaryState(summary);
   
          return updatedTransactions;
        });
  
        // Show success message
        setMessageState('Success:Transaction added successfully!');
      } else {
        console.error('Error:Failed to add transaction!');
      }
    } catch (error) {
      setMessageState(`Error:${error.message}`);
    } finally {
      setLoading(false);
    }

    close_AddNewTransactionModal();
  };
  
  // Function to update a transaction
  const update_Transaction = async (updatedTransactionFromModal) => {
    setLoading(true);
    try {
        // Send a PUT request to update the transaction in the backend
        const res = await axios.put(`${backendHostName}/transactions/updatetransaction.php?id=${updatedTransactionFromModal.id}`, 
          { date_paid: updatedTransactionFromModal.date_paid, payment_acceptedby: user.whoIsLoggedIn });
        
        if (res.data) {
            // Assuming res.data is the updated transaction
            const updated_Transaction_From_Backend = res.data;

            // Update the transaction in the state by replacing the old one
            setTransactions((prevTransactions) => {
                // Replace the old transaction with the updated one in the array
                const updatedTransactionsTable = prevTransactions.map((transaction) =>
                    transaction.id === updatedTransactionFromModal.id ? updated_Transaction_From_Backend : transaction
                );

                const summary = calculateTransactionSummary(updatedTransactionsTable, formatDate(new Date()));

                updateSummaryState(summary);

                return updatedTransactionsTable; // Returning the updated transactions is redundant, since the state is updated via setTransactions
            });

            // Show success message
            setMessageState(`Success:Sukli = ₱ ${paymentChange.toFixed(2)}`);
        } else {
          setMessageState('Error:Failed to update transaction!');
        }

        close_UpdateTransactionModal();  // Close the modal after the update
    } catch (error) {
        setMessageState(`Error:${error.message}`);
    } finally {
        setLoading(false);
    }
  };

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const currentDate = formatDate(new Date());
  
      // Fetch the transactions from the backend
      const res = await axios.get(`${backendHostName}/transactions/fetchtransactions.php?dt=${currentDate}`);
  
      if (res.data) {
        const fetched_Transactions = res.data.sort((a, b) => new Date(b.date_time_accepted) - new Date(a.date_time_accepted));
  
        const summary = calculateTransactionSummary(fetched_Transactions, currentDate);

        updateSummaryState(summary);

        setTransactions(fetched_Transactions);
      }
    } catch (err) {
      setMessageState('Error:Error fetching transactions!');
      //console.error("Error fetching transactions:", err);
    } finally {
      setLoading(false);
    }
  };
    
  useEffect(() => {
    fetchTransactions();
  }, []);

  return (

    <div className='relative overflow-hidden min-h-screen'>
        {/*

        <div className='absolute inset-0 z-[-1]'>
        <img
            src={DashboardBackground}
            alt= "Laundry Background"
            className='object-cover w-full h-full sm:h-auto'
        />
        </div>

        */}
    
        <div className="dashboard w-full">

            {/*---- Nav Bar ---- */}
            <div className="dashboard-nav-bar flex justify-between items-center bg-[#229799] shadow-md px-4 py-3">
                <button onClick={toggleMenu}>
                    <Bars3Icon aria-hidden="true" className="h-7 w-7 mt-1 text-white" />
                </button>
                <div className="dashboard-company-title text-lg font-bold flex-1 z-20"><label style={{textShadow: "1px 1px 2px black"}} className="text-fuchsia-400 drop-shadow-md">Myla</label><label style={{textShadow: "1px 1px 2px black"}} className="text-indigo-300 drop-shadow-md">bandera</label></div>
                <div className="dashboard-navigation flex items-center">
                    <UserIcon aria-hidden="true" className="h-6 w-6 p-1 text-white" /><span className="mr-1 text-1xl text-white font-semibold">{user.whoIsLoggedIn}</span>
                    <button onClick={handleLogout} className="btnLogout bg-[#D32F2F] text-white px-2 py-1 rounded text-xs shadow-md">Logout</button>
                </div>
            </div>
            
            {/*---- Slide-out Menu ---- */}
            <div className='flex-none w-9'> 
                <div className={`menu ${isOpen ? 'open' : ''} z-10`}>
                <ul className='mt-4'>
                    <li><hr /></li>
                    <li className='cursor-pointer' onClick={handleEmployees}>Employees</li>
                    <li className='cursor-pointer'><b className='text-yellow-400'>To-do:</b> Reports</li>
                    <li><hr /></li>
                    <li className='cursor-pointer' onClick={toggleBussinessOpen}>Open/Close Bussiness</li>
                    <li><hr /></li>
                    <li className='cursor-pointer' onClick={toggleMenu}>Close</li>
                </ul>
                </div>
            </div>

            {/*---- Date ---- */}
            <div className="text-left text-1xl ml-4 mt-2 my-0 font-bold">{new Date().toLocaleDateString('en-US', option)}</div>

            {loading ? (
              <Spinner />  // Show spinner when loading is true
            ) : (<>

            {/*---- Cash, Recievables ---- */}
            <div className="summary-container flex flex-wrap gap-x-3 px-4 my-3">
                <div className="summary-box bg-green-100 px-3 py-2 rounded shadow-md flex-1">
                  <div className="flex justify-between items-center">
                      <BanknotesIcon aria-hidden="true" className="h-5 w-5 text-gray-500 flex-none" /><label className="text-gray-500 flex-1 pl-1">Cash</label>
                      <label className="text-right text-gray-500 font-bold">₱</label>
                  </div>
                  <h2 style={{textShadow: "1px 1px 2px white"}} className="text-lime-600 text-3xl mt-2 mb-4 font-semibold text-center">{formatNumber(cash)}</h2>
                </div>
                <div className="summary-box bg-red-100 px-3 py-2 rounded shadow-md flex-1">
                  <div className="flex justify-between items-center">
                      <TicketIcon aria-hidden="true" className="h-5 w-5 text-gray-500 flex-none" /><label className="text-gray-500 flex-1 pl-1">Receivables</label>
                      <label className="text-right text-gray-500 font-bold">₱</label>
                  </div>
                  <h2 style={{textShadow: "1px 1px 2px white"}} className="text-red-600 text-3xl mt-2 mb-4 font-semibold text-center">{formatNumber(receivables)}</h2>
                </div>
            </div>

            {/*---- Summary Table, Add New Button ---- */}
            <div className="summary-services flex px-2 gap-x-3 mx-2 my-3">
              <table className="border-collapse  m-0 flex-1 shadow-md border-2 border-black">
                  <tbody>
                    {summaryServices.map((serv) => (
                        <tr key={serv.service}>
                        <td className={serv.service === 'Total Loads (Today)' ? "border border-black px-2 py-0 text-xs text-right font-bold" : "border border-black px-2 py-0 text-xs text-left"}>{serv.service}</td>
                        <td className={serv.service === 'Total Loads (Today)' ? "border border-black px-2 py-0 text-center text-xs bg-green-100 font-bold" : "border border-black px-2 py-0 text-center text-xs bg-green-100"}>{serv.loadsPaid ? <> {serv.loadsPaid} </> : <></>}</td>
                        <td className={serv.service === 'Total Loads (Today)' ? "border border-black px-2 py-0 text-center text-xs bg-red-100 font-bold" : "border border-black px-2 py-0 text-center text-xs bg-red-100"}>{serv.loadsUnpaid ? <> {serv.loadsUnpaid} </> : <></>}</td>
                        </tr>
                    ))}
                </tbody>
              </table>
              <div className="new-transactionbutton flex items-center justify-center flex-1">
                  <button onClick={() => open_AddNewTransactionModal()} className="btnNewtransaction bg-[#2E7D32] text-white text-lg px-4 py-4 rounded-md h-[100%] w-[100%] shadow-md">
                  <PlusCircleIcon aria-hidden="true" className="h-6 w-6 m-auto text-white" /> New Transaction
                  </button>
              </div>
            </div>


            {/*---- Transactions Table Title ---- */}
            <div className='flex items-center w-full pr-4 mb-1'>
              <ShoppingCartIcon aria-hidden="true" className="h-5 w-5 ml-4 text-gray flex-none" />     
              <label className="text-lg font-bold pl-1 mt-1 flex-1 mr-5">Transactions:</label>
              <label className="text-md text-right mt-1 pr-2 flex-1">Bussiness:</label>
              {settings.bussinessOpen ?
              (<>
                  <CheckCircleIcon aria-hidden="true" className="h-5 w-5 text-white mt-1.5 pl-1 flex-none text-left z-10" />
                  <label className='flex-3 mt-1 ml-[-20px] pl-6 pr-2 text-md text-white border rounded-full bg-green-500'>Open</label>
                </>
               ) : (<>
                  <XCircleIcon aria-hidden="true" className="h-5 w-5 text-white mt-1.5 pl-1 flex-none text-left z-10" />
                  <label className='flex-3 mt-1 ml-[-20px] pl-6 pr-2 text-md text-white border rounded-full bg-red-500'>Closed</label>
                </>
              )}  
            </div>

            {/*---- Transactions Table Container ---- */}
            <div className="max-h-[400px] overflow-y-auto shadow-md mx-4 border rounded-md">

              {/*---- Transactions Table ---- */}
              <div className="overflow-x-auto max-h-[380px]">
                  <div className="flex flex-col">
                  {/* Header */}
                  <div className="flex bg-[#48CFCB] text-[#424242] p-2 sticky top-0 z-5">
                      <div className="flex-[2] px-1 font-semibold text-center">Particulars</div>
                      <div className="flex-[1] px-1 font-semibold text-center">Status</div>
                  </div>

                  {/* Table Body (scrollable) */}
                  <div className="flex flex-col overflow-y-auto max-h-[335px]">
                      {transactions.map((transaction) => (
                      <div key={transaction.id} className="flex p-2 shadow-md">

                          {/* Name Column */}
                          <div className="flex-[2] px-1">
                            <div className="text-xs text-left">
                              {showDate(transaction.date_time_accepted)} - {formatTime(transaction.date_time_accepted)}
                            </div>
                              {user.isAdmin ? <button className='text-xs text-white rounded bg-red-500 py-1 px-2 shadow-sm mr-2' onClick={() => confirmDeleteTransaction(transaction.id, `${transaction.name} - ${transaction.service}(${transaction.loads}) ₱${transaction.total}` )}>Del</button> : null}
                              <b>{transaction.name}</b> - ₱<b>{typeof transaction.total === 'number' && !isNaN(transaction.total) ? transaction.total.toFixed(2) : '0.00'}</b>
                            <div className="text-xs text-left">
                              ({transaction.loads}) Load/s {transaction.service} - {transaction.bywho}
                            </div>
                          </div>

                          {/* Total Column
                          <div className="flex-[1] px-1 text-center">
                          <div>₱<b>{typeof transaction.total === 'number' && !isNaN(transaction.total) ? transaction.total.toFixed(2) : '0.00'}</b></div>
                          <div className="text-xs">{transaction.service}</div>
                          <div className="text-xs">Loads ({transaction.loads})</div>
                          </div> */}

                          {/* Status Column */}
                          <div className="flex-[1] px-1 text-center">
                          {!transaction.date_paid ? (
                              <button 
                              className="btnUnpaid bg-red-500 text-xs text-white m-3 p-2 rounded shadow-md" 
                              onClick={() => open_UpdateTransactionModal(transaction, (0 - transaction.total))}
                              disabled={loading} // Set loading state while waiting for API response
                              >
                              <b>Unpaid</b>
                              </button>
                          ) : (
                              <div>
                                <b>Paid</b>
                                <div className="text-xs">{showDate(transaction.date_paid)}</div>
                                <div className="text-xs">{formatTime(transaction.date_time_accepted)} - {transaction.payment_acceptedby}</div>
                              </div>
                          )}
                          </div>
                      </div>
                      ))}
                  </div>
                  </div>
              </div>
              {/*---- End Transactions Table ---- */}

            </div>
              {/*---- End Transactions Table ---- */}

            </>)}

        </div>
        {/*---- End Dashboard ---- */}
        
      {/* Update Transaction Modal */}
      {updateTransactionModalState && (
        <UpdateTransactionModal
            transaction = {selected_Transaction}
            paymentChange = {paymentChange}
            setPaymentChange = {setPaymentChange}
            onUpdate = {update_Transaction}
            onClose = {close_UpdateTransactionModal}
        />
      )}

      {/* Add New Transaction Modal */}
      {addNewTransactionModalState && (
        <AddNewTransactionModal 
            setLoading = {setLoading}
            onAdd = {addNew_Transaction}
            onClose = {close_AddNewTransactionModal} 
        />
      )}

      {/* Message Modal */}
      {messageState && 
        <MessageModal 
            messageContent={messageState} 
            onClose={close_MessageModal}
            onYes={handleDeleteTransaction}
         />}

    </div>
  );

};

export default Dashboard;
